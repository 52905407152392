/** @format */

import React, { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import "./NavBar.css";
import MainLogo from "../../images/MainLogo.png";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="container-nav">
      <div className="navbar">
        <div className="logo" style={{ width: "250px" }}>
          {/* Add your logo here */}
          <img src={MainLogo} alt="Company Logo" className="logo-img" />
        </div>
        <div className={`nav-links ${isOpen ? "open" : ""}`}>
          <ul>
            <li>
              <a href="#home">Home</a>
            </li>
            <li>
              <a href="#services">Services</a>
            </li>
            <li>
              <a href="#contact">Contact</a>
            </li>
          </ul>
        </div>
        <div className="hamburger" onClick={handleToggle}>
          {isOpen ? (
            <FaTimes className="hamburger-icon" />
          ) : (
            <FaBars className="hamburger-icon" />
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
