/** @format */

import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import "./contactForm.css";

const ContactForm = () => {
  const form = useRef();

  const [submitStatus, setSubmitStatus] = useState(null);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_EMAIL_SERVICE_ID,
        process.env.REACT_APP_EMAIL_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_EMAIL_USER_ID
      )
      .then(
        (result) => {
          setSubmitStatus("success");
          form.current.reset();
          setTimeout(() => {
            setSubmitStatus(null);
          }, 5000);
        },
        (error) => {
          setSubmitStatus("failure");
        }
      );
  };

  return (
    <form ref={form} onSubmit={sendEmail} className="contact-form">
      <label htmlFor="first-name">First Name</label>
      <input
        type="text"
        id="first-name"
        name="first-name"
        placeholder="First Name"
        required
      />

      <label htmlFor="last-name">Last Name</label>
      <input
        type="text"
        id="last-name"
        name="last-name"
        placeholder="Last Name"
        required
      />

      <label htmlFor="email">Email Address</label>
      <input
        type="email"
        id="email"
        name="email"
        placeholder="Email Address"
        required
      />

      <label htmlFor="message">Your Message</label>
      <textarea
        id="message"
        name="message"
        placeholder="Your Message"
        rows="4"
        required
      ></textarea>

      <button
        type="submit"
        className={`submit-button ${
          submitStatus === "success" ? "success" : ""
        } ${submitStatus === "failure" ? "failure" : ""}`}
      >
        Submit
      </button>
    </form>
  );
};

export default ContactForm;
