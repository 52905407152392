import React from "react";
import "./companyInfo.css";
import phoneIcon from "../../../icons/phone-call-svgrepo-com.svg";
import mailIcon from "../../../icons/envelope-svgrepo-com.svg";
import pinIcon from "../../../icons/house-svgrepo-com.svg";
import hoursIcon from "../../../icons/business-hours-svgrepo-com.svg";

const CompanyInfo = () => {
  return (
    <div className="contact-container">
      <h2>Contact us</h2>
      <div className="contact-item">
        <img className="icon-phone icon" src={phoneIcon} />
        <span>074 114 9029</span>
      </div>
      <div className="contact-item">
        <img className="icon-phone icon" src={mailIcon} />
        <span>mpdattorneys@outlook.com</span>
      </div>
      <div className="contact-item">
        <img className="icon-phone icon" src={pinIcon} />
        <span className="address-text">
          Main Office: 50 Constantia Boulevard,
          <br />
          Quadrum Office Park, Building No. 4, Ground Floor,
          <br />
          Constantia Kloof, Johannesburg, 1709
        </span>
      </div>
      <div className="contact-item">
        <img className="icon-phone icon" src={hoursIcon} />
        <span>Mon to Fri – 8:00am to 4:00pm</span>
      </div>
    </div>
  );
};

export default CompanyInfo;
