import React from "react";
import "./contact.css";
import CompanyInfo from "./CompanyInfo/CompanyInfo";
import ContactForm from "./ContactForm/ContactForm";

const Contact = () => {
  return (
    <div id="contact" className="contact-body">
      <CompanyInfo />
      <ContactForm />
    </div>
  );
};

export default Contact;
