import "./App.css";
import phoneIcon from "./icons/phone-call-svgrepo-com.svg";
import mailIcon from "./icons/envelope-svgrepo-com.svg";
import pinIcon from "./icons/house-svgrepo-com.svg";
import hoursIcon from "./icons/business-hours-svgrepo-com.svg";
import Navbar from "./components/Navbar/NavBar";
import Heading from "./components/Heading/Heading";
import Line from "./components/Shared/Line/Line";
import Footer from "./components/Footer/Footer";
import Services from "./components/Services/Services";
import Contact from "./components/Contact/Contact";

// const services = [
//   {
//     title: "Civil law & litigation",
//     description: [
//       "General Civil Litigation",
//       "Debt collection",
//       "Liquidations",
//       "Evictions",
//       "Drafting of Agreements",
//     ],
//     image: civilLaw,
//   },
//   {
//     title: "Criminal law",
//     description: [
//       "Bail applications",
//       "Drunk driving",
//       "Reckless & negligent driving",
//       "Assault (common and GBH)",
//       "Fraud",
//       "Theft",
//       "And more",
//     ],
//     image: crimLaw,
//   },
//   {
//     title: "Family law",
//     description: [
//       "Unopposed divorces",
//       "Opposed divorces",
//       "Adoption",
//       "Paternity",
//       "Domestic partnership agreements",
//       "Change of matrimonial property systems",
//       "Domestic violence and harassment orders",
//       "Maintenance disputes",
//       "Contact and Care of minor children",
//       "Antenuptial Agreements / marriage contracts",
//     ],
//     image: familyLaw,
//   },
//   {
//     title: "Estates",
//     description: ["Drafting of Wills", "Administration of deceased estates"],
//     image: estates,
//   },
//   {
//     title: "Road accident fund",
//     description: [
//       "Involved in a road accident?",
//       "Maybe you lost a breadwinner as a result thereof.",
//       "We can assist with instituting a claim against the Road Accident Fund for compensation.",
//     ],
//     image: roadAccidentFund,
//   },
//   {
//     title: "Public liability",
//     description: [
//       "Have you been injured in a restaurant or shopping center due to a wet and slippery floor?",
//       "If you have been injured in a public place you may have grounds for a public liability claim.",
//     ],
//     image: publicLiability,
//   },
//   {
//     title: "Medical negligence claims",
//     description: [
//       "Are you suffering harm because of the negligence of a medical professional such as a doctor or nurse?",
//       "We can assist with your claim.",
//     ],
//     image: medicalNegligence,
//   },
//   {
//     title: "Labour law",
//     description: [
//       "CCMA",
//       "Labour Court",
//       "Disciplinary Hearings",
//       "Drafting of relevant contracts and/or documents",
//       "We offer retainer packages to suit the needs of any company.",
//     ],
//     image: labourLaw,
//   },
//   {
//     title: "Arrear levy collections",
//     description: [
//       "Dispatching National Credit Act letter of demands.",
//       "Issuing and service of summons.",
//       "Obtaining of default judgment.",
//       "Issuing of warrants of execution.",
//       "Attaching and selling debtors movable and immovable property.",
//       "Attending to sequestration and liquidation proceedings.",
//     ],
//     image: arrearLevyCollections,
//   },
// ];

const contact = [
  {
    text: "074 114 9029",
    icon: phoneIcon,
  },
  {
    text: "mpdattourneys@outlook.com",
    icon: mailIcon,
  },
  {
    text: "Main Office: 50 Constantia Boulervard, Quadrum Office Park, Building No. 4, Ground Floor, Constantia Kloof, Johannesburg, 1709",
    icon: pinIcon,
  },
  {
    text: "Mon to Fri – 8:00am to 4:00pm",
    icon: hoursIcon,
  },
];

function App() {
  return (
    // <>
    //   <Navbar />
    //   <div className="App">

    //     <section id="services" className="services-section">
    //       {services.map((service, index) => (
    //         <section key={index} className="service-item">
    //           <h1 className="service-title">{service.title}</h1>
    //           <div className="service-item-body">
    //             <ul className="services-item-description">
    //               {service.description.map((item, idx) => (
    //                 <li key={idx}>{item}</li>
    //               ))}
    //             </ul>
    //             <img
    //               src={service.image}
    //               alt={service.title}
    //               className="services-image img"
    //             />
    //           </div>
    //         </section>
    //       ))}
    //     </section>
    //     <section id="contact" className="contact-section">
    //       <div className="contact-body">
    //         {/* <div className="contact-left">
    //         <h2 className="contact-left-title">Contact Us</h2>
    //         {contact.map((item, index) => (
    //           <div className="contact-section-item" key={index}>
    //             <img className="contact-icon" src={item.icon} alt="icon" />
    //             <span className="contact-text">{item.text}</span>
    //           </div>
    //         ))}
    //       </div> */}
    //         <CompanyInfo />
    //         <div className="contact-right">
    //           <ContactForm />
    //         </div>
    //       </div>
    //     </section>
    //   </div>
    // </>
    <div className="app">
      <Navbar />
      <Heading />
      <Line />
      <Services />
      <Line />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
