import React from 'react'
import './Footer.css'
import MainLogo from '../../images/MainLogo.png'

const Footer = () => {
  return (
    <div className='footer'>
        <img src={MainLogo} alt="" />
    </div>
  )
}

export default Footer