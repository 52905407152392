/** @format */

import React from "react";
import homeBook from "../../images/bookHome.jpg";
import profile from "../../images/MP2.png";
import "./Heading.css";

const Heading = () => {
  return (
    <div id='home' className='home-section'>
      <div className='home'>
        <div className='home-image'>
          <img src={homeBook} alt='homebook' />
        </div>
        <div className='home-text'>
          <p>
            Marius Petrus Delport Attorneys is a dynamic firm, committed to
            providing clients with legal services of a high professional
            standard offering a wide range of legal services. We make it a
            priority that your matters are dealt with efficiently,
            professionally and remain stress-free. We opened our doors in April
            2021 with the goal of providing comprehensive tailored legal
            services to individuals and business owners alike.
          </p>
        </div>
      </div>
      <div className='home'>
        <div className='home-text'>
          <p>
            The firm was founded by Marius Petrus Delport. After obtaining his
            LLB degree from the University of Johannesburg, Marius has been
            practicing as a legal practitioner since 2020. With our friendly and
            professional staff, along with our vast network of senior and junior
            advocates, we will ensure that each client’s specific needs are well
            catered for.
          </p>
        </div>
        <div className='home-image'>
          <img src={profile} className='' alt='profile' />
        </div>
      </div>
    </div>
  );
};

export default Heading;
