import React from "react";
import "./Services.css";
import civilLaw from "../../images/civilLaw.png";
import crimLaw from "../../images/crimLaw.png";
import familyLaw from "../../images/famLaw.png";
import estates from "../../images/estates.png";
import roadAccidentFund from "../../images/raf.png";
import publicLiability from "../../images/public.png";
import medicalNegligence from "../../images/medical.png";
import labourLaw from "../../images/labour.png";
import arrearLevyCollections from "../../images/effective-levy-collection.png";
import Line from "../Shared//Line/Line";

const services = [
  {
    title: "Civil law & litigation",
    description: [
      "General Civil Litigation",
      "Debt collection",
      "Liquidations",
      "Evictions",
      "Drafting of Agreements",
    ],
    image: civilLaw,
  },
  {
    title: "Criminal law",
    description: [
      "Bail applications",
      "Drunk driving",
      "Reckless & negligent driving",
      "Assault (common and GBH)",
      "Fraud",
      "Theft",
      "And more",
    ],
    image: crimLaw,
  },
  {
    title: "Family law",
    description: [
      "Unopposed divorces",
      "Opposed divorces",
      "Adoption",
      "Paternity",
      "Domestic partnership agreements",
      "Change of matrimonial property systems",
      "Domestic violence and harassment orders",
      "Maintenance disputes",
      "Contact and Care of minor children",
      "Antenuptial Agreements / marriage contracts",
    ],
    image: familyLaw,
  },
  {
    title: "Estates",
    description: ["Drafting of Wills", "Administration of deceased estates"],
    image: estates,
  },
  {
    title: "Road accident fund",
    description: [
      "Involved in a road accident?",
      "Maybe you lost a breadwinner as a result thereof.",
      "We can assist with instituting a claim against the Road Accident Fund for compensation.",
    ],
    image: roadAccidentFund,
  },
  {
    title: "Public liability",
    description: [
      "Have you been injured in a restaurant or shopping center due to a wet and slippery floor?",
      "If you have been injured in a public place you may have grounds for a public liability claim.",
    ],
    image: publicLiability,
  },
  {
    title: "Medical negligence claims",
    description: [
      "Are you suffering harm because of the negligence of a medical professional such as a doctor or nurse?",
      "We can assist with your claim.",
    ],
    image: medicalNegligence,
  },
  {
    title: "Labour law",
    description: [
      "CCMA",
      "Labour Court",
      "Disciplinary Hearings",
      "Drafting of relevant contracts and/or documents",
      "We offer retainer packages to suit the needs of any company.",
    ],
    image: labourLaw,
  },
  {
    title: "Arrear levy collections",
    description: [
      "Dispatching National Credit Act letter of demands.",
      "Issuing and service of summons.",
      "Obtaining of default judgment.",
      "Issuing of warrants of execution.",
      "Attaching and selling debtors movable and immovable property.",
      "Attending to sequestration and liquidation proceedings.",
    ],
    image: arrearLevyCollections,
  },
];

const finalServices = [
  {
    description:
      "We strive to build lifelong relationships with all our instructing attorneys by providing service of the highest calibre. We are the preferred correspondent attorneys for more than 50 law firms around South Africa.",
    duties: [
      "Issuing of Documents",
      "Deliver the required documents to the relevant Sheriffs",
      "Service and Filing",
      "Obtaining of Court Dates",
      "Index and Pagination of the Court File",
      "Court Appearances",
      "Urgent Applications",
      "Applying for Default Judgment and Lodging Answers to Magistrates Queries",
      "All duties relating to Court Online and CaseLines",
    ],
  },
  {
    description:
      "We have jurisdiction in the following courts and administrative offices:",
    duties: [
      "Johannesburg High Court (South Gauteng High Court)",
      "Johannesburg Regional Court and Magistrates Court",
      "Johannesburg Master’s Office",
      "Johannesburg Labour Court",
      "The Constitutional Court",
      "Randburg Regional Court and Magistrates Court",
      "Roodepoort Regional Court and Magistrates Court",
    ],
    additional_info:
      "We regularly appear in the abovementioned courts and can guide and advise you on what practices to follow to avoid any unnecessary delays in finalising your matter. In addition thereto, our fees are based on the prescribed court tariffs however, if we receive instructions in bulk, we are open to certain fixed fees.",
  },
];

const correspService = {
  title: "Criminal law",
  description: [
    "We strive to build lifelong relationships with all our instructing attorneys by providing service of the highest calibre.",
    "We are the preferred correspondent attorneys for more than 50 law firms around South Africa.",
    "We attend to all correspondent duties such as:",
    "Issuing of Documents",
    "Deliver the required documents to the relevant Sheriffs",
    "Service and Filing",
    "Obtaining of Court Dates",
    "Index and Pagination of the Court File",
    "Court Appearances",
    "Urgent Applications",
    "Applying for Default Judgment and Lodging Answers to Magistrates Queries",
    "All duties relating to Court Online and CaseLines",
    "We have jurisdiction in the following courts and administrative offices:",
    "Johannesburg High Court (South Gauteng High Court)",
    "Johannesburg Regional Court and Magistrates Court",
    "Johannesburg Master’s Office",
    "Johannesburg Labour Court",
    "The Constitutional Court",
    "Randburg Regional Court and Magistrates Court",
    "Roodepoort Regional Court and Magistrates Court",
    "We regularly appear in the abovementioned courts and can guide and advise you on what practices to follow to avoid any unnecessary delays in finalising your matter.",
    "In addition thereto, our fees are based on the prescribed court tariffs however, if we receive instructions in bulk, we are open to certain fixed fees.",
  ],
  image: crimLaw,
};

const Services = () => {
  return (
    <div>
      <section id="services" className="services-section">
        {services.map((service, index) => (
          <section key={index} className="service-item">
            <h1 className="service-title">{service.title}</h1>
            <div className="service-item-body">
              <ul className="services-item-description">
                {service.description.map((item, idx) => (
                  <li key={idx}>{item}</li>
                ))}
              </ul>
              <img
                src={service.image}
                alt={service.title}
                className="services-image img"
              />
            </div>
          </section>
        ))}
        <br />
      </section>
      <Line />
      <div className="last-service-container">
        <h2 className="last-service-container-heading">
          CORRESPONDENT SERVICE
        </h2>
        <div className="another-container">
          {finalServices.map((item) => (
            <section className="last-service-item">
              <>
                <h1 className="last-service-title">{item.description}</h1>
                <div className="last-service-item-body">
                  <ul className="last-services-item-description">
                    {item.duties.map((item, idx) => (
                      <li key={idx}>{item}</li>
                    ))}
                  </ul>
                  <p className="additional_info">{item.additional_info}</p>
                </div>
              </>
            </section>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Services;
