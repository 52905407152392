/** @format */

import React from "react";
import "./Line.css";

const Line = () => {
  return <div className='line'></div>;
};

export default Line;
